import {
    authorize,
    checkout,
    getSession,
    getStyle,
    layerAdd,
    preprocess,
    shareSession,
    updateSession
} from '../../api.js';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal.js';
import i18n from '../../i18n.js';
import { notifications } from '@mantine/notifications';
import Carousel from 'nuka-carousel';
import PaymentModal from '../PaymentModal/PaymentModal.js';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FaArrowLeft,
    FaArrowRight,
    FaCartShopping,
    FaCheck,
    FaCirclePlus,
    FaDownload,
    FaLightbulb,
    FaNewspaper,
    FaRecycle
} from 'react-icons/fa6';
import StyleSelectModal from '../StyleSelectModal/StyleSelectModal.js';
import './Stylizer.css';

function Stylizer() {
    const [currentTip, setCurrentTip] = useState(0);
    const [email, setEmail] = useState('');
    const [genCount, setGenCount] = useState(0);
    const [height, setHeight] = useState(0);
    const [isConfirmExportModalOpen, setConfirmExportModalOpen] =
        useState(false);
    const [isConfirmPaymentModalOpen, setConfirmPaymentModalOpen] =
        useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);
    const [isSessionActive, setIsSessionActive] = useState(false);
    const [items, setItems] = useState(Object.create(null));
    const [layers, setLayers] = useState(Object.create(null));
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const [room, setRoom] = useState(null);
    const [sessionID, setSessionID] = useState('');
    const [sliderIndex, setSliderIndex] = useState(1);
    const [stripeID, setStripeID] = useState('');
    const [styleModalOpen, setStyleModalOpen] = useState(false);
    const [styles, setStyles] = useState(() => {
        const _exterior = process.env.REACT_APP_EXTERIOR.split(',');
        const _interior = process.env.REACT_APP_INTERIOR.split(',');

        return {
            exterior: _exterior.reduce((acc, style) => {
                acc[style] = {
                    count: 0,
                    selectedModifier: null,
                    modifiers: {
                        construction: null,
                        landscaping: null
                    }
                };
                return acc;
            }, {}),
            interior: _interior.reduce((acc, style) => {
                acc[style] = { count: 0, modifiers: { furniture: null } };
                return acc;
            }, {})
        };
    });
    const [upload, setUpload] = useState('');
    const [width, setWidth] = useState(0);

    const abortController = new AbortController();

    const blobToBase64 = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(blob);
        });
    };

    const closeStyleModal = () => {
        setStyleModalOpen(false);
    };

    const handleCheckoutClick = () => {
        setModalVisible(true);
    };

    const handleCheckoutResume = async () => {
        await checkout(sessionID);
        notifications.show({
            autoClose: 6000,
            color: 'green',
            icon: <FaCheck />,
            loading: false,
            message: i18n.t('toast.payment'),
            title: i18n.t('toast.complete'),
            withCloseButton: false
        });
        setConfirmPaymentModalOpen(true);
    };

    const handleClose = () => {
        setModalVisible(false);
    };

    const handleConfirmCheckout = async () => {
        await checkout(sessionID);
        notifications.show({
            autoClose: 3000,
            color: 'green',
            icon: <FaCheck />,
            loading: false,
            message: i18n.t('toast.completed', {
                amount: `${parseFloat(genCount * 0.5).toFixed(2)}`
            }),
            title: i18n.t('toast.complete'),
            withCloseButton: false
        });
        localStorage.removeItem('session_id');
        setModalVisible(false);
        setIsSessionActive(false);
        setItems(Object.create(null));
    };

    const handleConfirmCurrent = async () => {
        const id = localStorage.getItem('session_id');

        if (id) {
            getSession(id)
                .then(async (session) => {
                    const authorization = await authorize(
                        session.id,
                        session.email,
                        process.env.REACT_APP_AMOUNT,
                        true
                    );

                    return { authorization, session };
                })
                .then(async ({ authorization, session }) => {
                    const price =
                        parseInt(session.price, 10) -
                        parseInt(process.env.REACT_APP_AMOUNT, 10);
                    const update = await updateSession(session.id, {
                        id: session.id,
                        email: email,
                        price: price,
                        stripe_id: authorization.stripe_id,
                        stripe_intent: authorization.intent,
                        status: true
                    });

                    notifications.show({
                        autoClose: 3000,
                        color: 'green',
                        icon: <FaCheck />,
                        loading: false,
                        message: i18n.t('toast.authorized'),
                        title: i18n.t('toast.authorization')
                    });

                    setGenCount(price);
                    setIsSessionActive(true);

                    return { update };
                });
        } else {
            setIsSessionActive(false);
            setConfirmPaymentModalOpen(true);
        }
    };

    const handleDownload = async () => {
        await Promise.all(
            Object.keys(items).map((item) => {
                const link = document.createElement('a');
                link.href = items[item].image;
                link.download = `${item.split('-')[0]}_${items[item].salt}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        );
    };

    const handleExport = async () => {
        const images = await Object.keys(items).reduce(async (acc, item) => {
            const urls = await acc;

            try {
                const style = `${item.split('-')[0]}_${items[item].salt}`;
                urls[style] = items[item].image.replace(
                    /^data:[^;]+;base64,/,
                    ''
                );

                return urls;
            } catch (error) {
                console.error(`Export Error [${item}]: ${error}`);
                return urls;
            }
        }, {});

        await shareSession(sessionID, {
            files: images,
            recipient: [email],
            subject: `${i18n.t('share.subject')} [${sessionID}]`,
            text: `${i18n.t('share.text')}`
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const img = new Image();

        img.src = await blobToBase64(file);

        img.onload = function () {
            setHeight(this.height);
            setWidth(this.width);
        };

        const base64 = img.src.replace(/^data:[^;]+;base64,/, '');

        handleRoomProcess(
            sessionID,
            base64,
            process.env.REACT_APP_ROOM_PROMPT,
            process.env.REACT_APP_SERVICE
        );
        const history = layerAdd(sessionID, base64);

        setItems(Object.create(null));
        setLayers(history);
        console.log('layers', layers);
        setStyleModalOpen(true);
        setUpload(img.src);

        event.target.value = null;
    };

    const handleFileInput = useRef(null);

    const handleRetry = async (item) => {
        const slide = items[item];
        const style = item.split('-')[0];

        slide.loading = true;

        getStyle(
            sessionID,
            slide.exterior ? 'exterior' : 'interior',
            slide.creative ? 'creative' : 'accurate',
            room ? room : slide.furniture,
            slide.height,
            slide.image.replace(/^data:[^;]+;base64,/, ''),
            `${process.env.REACT_APP_SERVICE}`,
            style,
            slide.width
        ).then(async (image) => {
            setGenCount((prevCount) => prevCount + 1);
            slide.image = image;
            slide.loading = false;

            return { image };
        });
    };

    const handleRoomProcess = async (
        id,
        image,
        prompt,
        service,
        signal = abortController.signal
    ) => {
        try {
            const reply = await preprocess(id, image, prompt, service, signal);
            setRoom(reply);
        } catch (error) {
            console.error(`Preprocess[room] Error: ${error}`);
        }
    };

    const keyRef = useRef([]);
    const { t } = useTranslation();
    const tips = [
        i18n.t('tip.one'),
        i18n.t('tip.two'),
        i18n.t('tip.three'),
        i18n.t('tip.four'),
        i18n.t('tip.five'),
        i18n.t('tip.six'),
        i18n.t('tip.seven'),
        i18n.t('tip.eight')
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTip((currentTip + 1) % tips.length);
        }, 6000);
        return () => clearInterval(interval);
    }, [currentTip]);

    useEffect(() => {
        const id = localStorage.getItem('session_id');

        if (id) {
            getSession(id).then((session) => {
                if (session.status) {
                    setEmail(session.email);
                    setGenCount(session.price);
                    setIsSessionActive(true);
                    setSessionID(session.id);
                    setStripeID(session.stripe_id);
                } else {
                    setIsSessionActive(false);
                    setPaymentModalOpen(true);
                }
            });
        } else {
            setIsSessionActive(false);
        }
    }, [getSession, paymentModalOpen]);

    useEffect(() => {
        if (keyRef.current[sliderIndex]) {
            keyRef.current[sliderIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    }, [sliderIndex]);

    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <>
            <div className="main">
                {isFirstLoading ? (
                    <div className="overlay">
                        <h2 className="animate-text">
                            {t('header.generating')}
                        </h2>
                        <div className="tip">
                            <FaLightbulb
                                style={{
                                    color: 'yellow',
                                    paddingRight: '10px'
                                }}
                            />
                            {tips[currentTip]}
                        </div>
                    </div>
                ) : (
                    <>
                        <ConfirmExportModal
                            isOpen={isConfirmExportModalOpen}
                            onCheckout={() => handleConfirmCheckout()}
                            onClose={() => setConfirmExportModalOpen(false)}
                            onDownload={() => handleDownload(true)}
                            onEmail={() => handleExport(true)}
                            t={t}
                        />
                        <ConfirmPaymentModal
                            isOpen={isConfirmPaymentModalOpen}
                            onClose={() => setConfirmPaymentModalOpen(false)}
                            onCurrent={() => handleConfirmCurrent()}
                            onNew={() => setPaymentModalOpen(true)}
                            t={t}
                        />
                        <PaymentModal
                            closeModal={() => setPaymentModalOpen(false)}
                            email={email}
                            isModalOpen={paymentModalOpen}
                            setEmail={setEmail}
                        />
                        <StyleSelectModal
                            closeModal={closeStyleModal}
                            ConfirmationModal={ConfirmationModal}
                            genCount={genCount}
                            handleCheckoutClick={handleCheckoutClick}
                            handleCheckoutResume={handleCheckoutResume}
                            handleClose={handleClose}
                            handleConfirmCheckout={handleConfirmCheckout}
                            height={height}
                            isModalOpen={styleModalOpen}
                            isModalVisible={isModalVisible}
                            sessionID={sessionID}
                            setGenCount={setGenCount}
                            setIsFirstLoading={setIsFirstLoading}
                            setIsModalOpen={setStyleModalOpen}
                            setItems={setItems}
                            setStyles={setStyles}
                            stripeID={stripeID}
                            styles={styles}
                            upload={upload}
                            uploadRef={handleFileInput}
                            width={width}
                        />

                        <input
                            accept="image/*;./*"
                            id="fileInput"
                            onChange={handleFileChange}
                            ref={handleFileInput}
                            style={{ display: 'none' }}
                            type="file"
                        />

                        {!Object.keys(items).length && (
                            <>
                                <div className="header">
                                    <h1>
                                        {isSessionActive && (
                                            <div
                                                className="money-box"
                                                style={
                                                    !genCount > 0
                                                        ? {
                                                              display: 'none'
                                                          }
                                                        : {}
                                                }
                                            >
                                                <span className="price">
                                                    $
                                                    {parseFloat(
                                                        genCount * 0.5
                                                    ).toFixed(2)}
                                                </span>
                                                <button
                                                    className="header-button"
                                                    onClick={
                                                        handleCheckoutClick
                                                    }
                                                >
                                                    <FaCartShopping />
                                                </button>
                                            </div>
                                        )}
                                    </h1>
                                    <>
                                        <button
                                            className="header-button"
                                            onClick={() => {
                                                window.open(
                                                    `${process.env.REACT_APP_BLOG}`,
                                                    '_blank'
                                                );
                                            }}
                                            style={{
                                                selfAlign: !isSessionActive
                                                    ? 'center'
                                                    : 'flex-end'
                                            }}
                                        >
                                            <FaNewspaper />
                                        </button>
                                    </>

                                    <ConfirmationModal
                                        cancelButtonText={t('cancel')}
                                        confirmButtonText={t('confirm')}
                                        content={t('payment.question')}
                                        onCancel={handleClose}
                                        onClose={handleClose}
                                        onConfirm={handleConfirmCheckout}
                                        show={isModalVisible}
                                        title={t('payment.title')}
                                    />
                                </div>
                                <div className="intro-container">
                                    <div className="Vibe">
                                        <h1>VIBE</h1>
                                        <p>{t('description')}</p>
                                    </div>
                                    <div className="tag-line">
                                        <p>{t('description')}</p>
                                    </div>
                                    <div className="design">
                                        <div className="button-holder">
                                            {isSessionActive ? (
                                                <button
                                                    className="glowing-btn"
                                                    onClick={() =>
                                                        document
                                                            .getElementById(
                                                                'fileInput'
                                                            )
                                                            .click()
                                                    }
                                                >
                                                    <span className="glowing-txt">
                                                        {t('glow.design.begin')}
                                                        <span className="faulty-letter">
                                                            {t(
                                                                'glow.design.middle'
                                                            )}
                                                        </span>
                                                        {t('glow.design.end')}
                                                    </span>
                                                </button>
                                            ) : (
                                                <button
                                                    className="glowing-btn"
                                                    onClick={
                                                        setPaymentModalOpen
                                                    }
                                                >
                                                    <span className="glowing-txt">
                                                        {t('glow.start.begin')}
                                                        <span className="faulty-letter">
                                                            {t(
                                                                'glow.start.middle'
                                                            )}
                                                        </span>
                                                        {t('glow.start.end')}
                                                    </span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="how-to-span">
                                        <p>{t('help.how_to')}</p>
                                    </div>
                                    <div className="how-to-photo"></div>
                                    <p className="transition-how-to-top">
                                        {t('help.how_to')}
                                    </p>
                                    <div className="transition-how-to"></div>
                                    <p className="transition-how-to-bottom">
                                        {t('help.intro')}
                                    </p>
                                    <div className="intro-span">
                                        <p>{t('help.intro')}</p>
                                    </div>
                                </div>
                            </>
                        )}
                        {Object.keys(items).length && (
                            <>
                                <div className="carousel-container">
                                    <div className="header">
                                        <h1>
                                            <div
                                                className="money-box"
                                                style={
                                                    isSessionActive &&
                                                    !genCount > 0
                                                        ? { display: 'none' }
                                                        : {}
                                                }
                                            >
                                                <span className="price">
                                                    $
                                                    {parseFloat(
                                                        genCount * 0.5
                                                    ).toFixed(2)}
                                                </span>
                                                <button
                                                    className="header-button"
                                                    onClick={
                                                        handleCheckoutClick
                                                    }
                                                >
                                                    <FaCartShopping />
                                                </button>
                                            </div>
                                        </h1>
                                        <button
                                            className="upload-button"
                                            onClick={() => {
                                                handleFileInput.current.click();
                                            }}
                                        >
                                            <FaCirclePlus className="upload-icon" />
                                        </button>
                                        <button
                                            className="header-button"
                                            disabled={Object.values(items).some(
                                                (item) => item.loading
                                            )}
                                            onClick={setConfirmExportModalOpen}
                                        >
                                            <FaDownload />
                                        </button>
                                        <ConfirmationModal
                                            cancelButtonText={t('cancel')}
                                            confirmButtonText={t('confirm')}
                                            content={t('payment.question')}
                                            onCancel={handleClose}
                                            onClose={handleClose}
                                            onConfirm={handleConfirmCheckout}
                                            show={isModalVisible}
                                            title={t('payment.title')}
                                        />
                                    </div>
                                    <Carousel
                                        beforeSlide={(_, v) =>
                                            setSliderIndex(v)
                                        }
                                        className="carouselZ"
                                        defaultControlsConfig={{
                                            nextButtonStyle: {
                                                backgroundColor: 'transparent',
                                                bottom: '0',
                                                padding: '0'
                                            },
                                            nextButtonText: (
                                                <div
                                                    className="icon-circle"
                                                    style={{
                                                        marginRight: '15px'
                                                    }}
                                                >
                                                    <FaArrowRight
                                                        size={'2em'}
                                                    />
                                                </div>
                                            ),
                                            prevButtonStyle: {
                                                backgroundColor: 'transparent',
                                                bottom: '0',
                                                padding: '0'
                                            },
                                            prevButtonText: (
                                                <div
                                                    className="icon-circle"
                                                    style={{
                                                        marginLeft: '15px'
                                                    }}
                                                >
                                                    <FaArrowLeft size={'2em'} />
                                                </div>
                                            )
                                        }}
                                        slideIndex={sliderIndex}
                                        swiping={true}
                                    >
                                        <div className="slide-image-container">
                                            <img
                                                alt="original"
                                                className="carousel-image"
                                                id="original"
                                                src={upload}
                                            />
                                        </div>
                                        {Object.keys(items).map((item) => {
                                            if (!items[item].loading) {
                                                return (
                                                    <>
                                                        <div className="slide-image-container">
                                                            <img
                                                                alt={item}
                                                                className="carousel-image"
                                                                id={`${item}_${items[item].salt}-img`}
                                                                src={
                                                                    items[item]
                                                                        .image
                                                                }
                                                            />
                                                            <div className="tool-menu">
                                                                <button
                                                                    className="retry-button"
                                                                    onClick={() =>
                                                                        handleRetry(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <FaRecycle />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            }
                                        })}
                                    </Carousel>
                                    <div className="carousel-bottom-container">
                                        <div className="carousel-keys-container">
                                            <div
                                                className={`carousel-keys ${sliderIndex === 0 ? 'carousel-key-selected' : ''}`}
                                                id="original-key"
                                                key="original"
                                                onClick={() => {
                                                    setSliderIndex(0);
                                                }}
                                                ref={(el) =>
                                                    (keyRef.current[0] = el)
                                                }
                                            >
                                                <div className="text">
                                                    ORIGINAL
                                                </div>
                                            </div>
                                            {Object.keys(items).map(
                                                (item, index) => (
                                                    <div
                                                        className={`carousel-keys ${sliderIndex === index + 1 ? 'carousel-key-selected' : ''}`}
                                                        id={`${item}_${items[item].salt}-key`}
                                                        key={item}
                                                        onClick={() => {
                                                            setSliderIndex(
                                                                index + 1
                                                            );
                                                        }}
                                                        ref={(el) =>
                                                            (keyRef.current[
                                                                index + 1
                                                            ] = el)
                                                        }
                                                    >
                                                        <div
                                                            className={`text ${items[item].loading ? 'blinking-text' : ''}`}
                                                        >
                                                            {item
                                                                .replace(
                                                                    '_',
                                                                    ' '
                                                                )
                                                                .split('-')[0]
                                                                .toUpperCase()}
                                                            {items[item]
                                                                .loading && (
                                                                <div className="spinner"></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

function ConfirmExportModal({
    isOpen,
    onClose,
    onEmail,
    onDownload,
    onCheckout,
    t
}) {
    if (!isOpen) {
        return null;
    }
    const [step, setStep] = useState(1);

    const handleDownloadOrEmail = (choice) => {
        if (choice) {
            onDownload();
        } else {
            onEmail();
        }
        setStep(2);
    };

    const handleContinueOrCheckout = (choice) => {
        if (choice) {
            onCheckout();
        }
        onClose();
    };

    return (
        <ConfirmationModal
            cancelButtonText={step === 1 ? t('email') : t('continue')}
            confirmButtonText={step === 1 ? t('download') : t('checkout')}
            content={step === 1 ? t('share.question') : t('payment.question')}
            onCancel={() =>
                step === 1
                    ? handleDownloadOrEmail(false)
                    : handleContinueOrCheckout(false)
            }
            onClose={onClose}
            onConfirm={() =>
                step === 1
                    ? handleDownloadOrEmail(true)
                    : handleContinueOrCheckout(true)
            }
            show={isOpen}
            title={() => (step === 1 ? t('share.title') : t('payment.title'))}
        />
    );
}

ConfirmExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCheckout: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    onEmail: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function ConfirmPaymentModal({ isOpen, onClose, onCurrent, onNew, t }) {
    if (!isOpen) {
        return null;
    }

    const handleCurrentOrNew = (choice) => {
        if (choice) {
            onCurrent();
        } else {
            onNew();
        }
        onClose();
    };

    return (
        <ConfirmationModal
            cancelButtonText={t('payment.new')}
            confirmButtonText={t('payment.current')}
            content={t('payment.options')}
            onCancel={handleCurrentOrNew(false)}
            onClose={onClose}
            onConfirm={handleCurrentOrNew(true)}
            show={isOpen}
            title={t('payment.option')}
        />
    );
}

ConfirmPaymentModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCurrent: PropTypes.func.isRequired,
    onNew: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

export default Stylizer;
